import React, { useCallback, useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "lib/firebase";
import UserContext from "contexts/UserContext";
import Cookies from "universal-cookie";

export default function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [authLanguageRedirect, setAuthLanguageRedirect] = useState(false);

  const cookies = new Cookies();
  const router = useRouter();
  const { asPath, pathname, query, push, locale } = router;
  let timeoutId = useRef();
  let tokenId = useRef();
  let localeRef = useRef(router.locale);
  // const [checkLoginInterval, setCheckLoginInterval] = useState(null);
  // console.log("user", user);
  // console.log("checkLoginInterval", checkLoginInterval);

  // These paths do not have login enforced
  const isAuthPage = !asPath
    ? undefined
    : !pathname
    ? undefined
    : [
        "/login",
        "/login_token",
        "/logout",
        "/register",
        "/useraction",
        "/organizations/new",
        "/organizations/newpro",
        "/sso",
        "/authorize",
        "/preview",
        "/domains/search",
        "/github-installation-success",
        "/securitycheck",
      ].includes(pathname)
    ? true
    : asPath.toString().includes("/applications/cart/0?status=subscribe");
  // Logout user function
  const logoutUser = async () => {
    console.log("Logging out user");
    setUser(null);
    setLogoutLoading(true);
    clearTimeout(timeoutId);
    tokenId.current = null;
    await signOut(auth);

    console.log("Redirecting to logout");
    const redirect = await push(
      {
        pathname: "/login",
        query: { session: "expired" },
      },
      undefined,
      { locale: localeRef.current }
    );
    console.log("redirect", redirect);
    setLogoutLoading(false);
  };

  // On auth pages, always use the navigator locale
  useEffect(() => {
    if (
      isAuthPage &&
      !authLanguageRedirect &&
      typeof window !== "undefined" &&
      !tokenId.current
    ) {
      const formattedBrowserLanguage = navigator.language.includes("fr")
        ? "fr"
        : "en";
      // console.log("Current locale:", locale);
      // console.log("Browser language:", formattedBrowserLanguage);
      if (locale !== formattedBrowserLanguage) {
        console.log("Redirecting to:", formattedBrowserLanguage);
        push(
          {
            pathname,
            query: query,
          },
          undefined,
          { locale: formattedBrowserLanguage }
        );
      }
      setAuthLanguageRedirect(true);
    }
  }, []);

  const updateProfile = useCallback(async (newUser) => {
    if (!loadingProfile) {
      setLoadingProfile(true);
      const impersonateToken =
        cookies.get("impersonateToken") === "undefined"
          ? ""
          : cookies.get("impersonateToken");
      const { uid, displayName, email, accessToken, preferences, phone, role } =
        newUser;
      tokenId.current = accessToken;
      if (tokenId.current) {
        const userProfile = await fetch("/api/profile", {
          headers: {
            Authorization: `Bearer ${tokenId.current}`,
            "Content-Type": "application/json",
          },
        }).then((res) => res.json());
        console.log("Load user profile");
        // Set timezone if not already set
        if (!userProfile?.preferences?.timeZone) {
          await fetch(`/api/profile`, {
            method: "PUT",
            body: JSON.stringify({
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenId.current}`,
            },
          });
        }
        // console.log("User language is", userProfile?.lang);
        // console.log("Locale is", locale);
        const user = {
          uid,
          displayName,
          email,
          accessToken,
          preferences: {
            ...preferences,
            timeZone:
              preferences?.timeZone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          phone,
          role,
          impersonateToken: impersonateToken,
          ...userProfile,
        };
        localeRef.current =
          user.lang ?? (navigator.language.includes("fr") ? "fr" : "en");
        // Set user state
        setUser(user);
        setLoadingProfile(false);

        // Manage email verification
        if (!auth?.currentUser?.emailVerified && userProfile?.createdAt) {
          try {
            const today = Date.now();
            const delta = Math.abs(today - Date.parse(userProfile?.createdAt));
            // Logout user if created more than 24H and email not verified
            // console.log("unverified user email");
            if (
              userProfile.restricted == true ||
              userProfile?.preferences?.timeZone.includes("Africa")
            ) {
              console.log("Redirecting to verify email");
              await push(
                {
                  pathname: "/useraction",
                  query: { status: "verifyEmail" },
                },
                undefined,
                { locale: localeRef.current }
              );
              return user;
            } else if (delta > 24 * 60 * 60 * 1000) {
              console.log("Redirecting to verify email");
              await push(
                {
                  pathname: "/useraction",
                  query: { status: "verifyEmail" },
                },
                undefined,
                { locale: localeRef.current }
              );
              return user;
            }
          } catch {
            return;
          }
        }
        // Sync InfrAPIs user
        fetch(`/api/users/sync`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenId.current}`,
          },
          body: JSON.stringify({
            userId: newUser.uid,
          }),
        });
        return user;
      }
    }
  }, []);

  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, async (authUser) => {
      try {
        if (authUser) {
          // Only update profile if we don't have user data or if the token has changed
          let userProfile;
          if (!user || user.accessToken !== authUser.accessToken) {
            console.log("Updating profile due to auth change");
            userProfile = await updateProfile(authUser);
            // console.log("user", userProfile);
          }
          tokenId.current = authUser.accessToken;
          console.log("userProfile", userProfile?.lang);
          console.log("authLanguageRedirect", authLanguageRedirect);
          localeRef.current =
            userProfile?.lang ??
            (navigator.language.includes("fr") ? "fr" : "en");

          // Handle language preference
          if (userProfile && !userProfile.lang) {
            console.log(
              "User profile language is not set, setting it to current locale"
            );
            await fetch(`/api/profile`, {
              method: "PUT",
              body: JSON.stringify({
                lang: locale,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenId.current}`,
              },
            });
          }
        }
      } catch (error) {
        console.error("Failed loading user", error);
        await logoutUser();
      } finally {
        setLoadingUser(false);
      }
    });

    return () => unsubscriber();
  }, []);

  // Redirect to login (this needs to be in an effect because it uses the router)
  useEffect(() => {
    const handleRedirect = async () => {
      // Manage logout
      if (
        loadingUser === false &&
        user === null &&
        isAuthPage === false &&
        !tokenId.current &&
        logoutLoading === false
      ) {
        console.log("Redirecting to login");
        // console.log(
        //   "Redirecting to login",
        //   loadingUser,
        //   user,
        //   isAuthPage,
        //   tokenId.current,
        //   logoutLoading
        // );
        // Redirect to next login page since most users will use next app
        if (!query?.redirectLink) {
          query.redirectLink = asPath;
        }
        console.log("Not logged in, redirecting to login");
        await push(
          {
            pathname: "/login",
            query: query,
          },
          undefined,
          { locale: localeRef.current }
        );
        return;
      }

      if (
        pathname.includes("/logout") &&
        user &&
        logoutLoading === false &&
        loadingUser === false
      ) {
        console.log("Logging out from logout");
        await logoutUser();
        return;
      }
      if (
        pathname.includes("/invitation-application") &&
        loadingUser === false &&
        user === null
      ) {
        console.log("Redirecting to login from invitation-application");
        await push(
          {
            pathname: "/login",
            query: query,
          },
          undefined,
          { locale: localeRef.current }
        );
        return;
      } else if (
        !pathname.includes("/invitation-application") &&
        pathname.includes("/invitation") &&
        loadingUser === false &&
        user === null
      ) {
        console.log("Redirecting to login from invitation");
        await push(
          {
            pathname: "/login",
            query: query,
          },
          undefined,
          { locale: localeRef.current }
        );
        return;
      }

      // Manage login
      if (
        pathname.includes("/login") &&
        user &&
        logoutLoading === false &&
        loadingUser === false
      ) {
        // Manage invitations
        const invitationsInStandby = user?.invitations.filter(
          (invitation) => invitation.status === "standby"
        );

        if (invitationsInStandby.length > 0) {
          if (invitationsInStandby[0].organization) {
            await push(
              {
                pathname: `/invitation`,
                query: {
                  organizationId: invitationsInStandby[0].organization,
                },
              },
              undefined,
              { locale: localeRef.current }
            );
            return;
          } else if (invitationsInStandby[0].application) {
            console.log(
              "Redirecting to invitation-application",
              invitationsInStandby[0].application
            );
            await push(
              {
                pathname: `/invitation-application`,
                query: { applicationId: invitationsInStandby[0].application },
              },
              undefined,
              { locale: localeRef.current }
            );
            return;
          }
        }

        // Manage redirection
        if (query?.redirectLink && query?.redirectLink.charAt(0) === "/") {
          console.log("Redirecting to redirect link");
          await push(
            {
              pathname: query?.redirectLink,
            },
            undefined,
            { locale: localeRef.current }
          );
          return;
        } else {
          console.log("No redirect page");
          if (user?.role === "admin") {
            console.log("Redirecting to admin applications management");
            await push(
              {
                pathname: "/admin/applications",
              },
              undefined,
              { locale: localeRef.current }
            );
            return;
          } else {
            console.log("Redirecting to applications management");
            await push(
              {
                pathname: "/applications/page/1",
                query: "",
              },
              undefined,
              { locale: localeRef.current }
            );
            return;
          }
        }
      }
    };
    handleRedirect();
  }, [
    asPath,
    user,
    query,
    pathname,
    loadingUser,
    isAuthPage,
    push,
    logoutLoading,
  ]);

  // Refresh token from Firebase hook
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (authUser) => {
      if (!loadingUser && !isAuthPage) {
        try {
          if (authUser) {
            // Get token details including expiration
            const tokenResult = await authUser.getIdTokenResult();
            const token = tokenResult.token;

            console.log("Token changed:", {
              expirationTime: new Date(tokenResult.expirationTime),
              issuedAtTime: new Date(tokenResult.issuedAtTime),
            });

            if (Date.now() >= new Date(tokenResult.expirationTime).getTime()) {
              console.log("Token expired, logging out");
              await logoutUser();
              return;
            }

            // Update token reference
            tokenId.current = token;

            // Update user profile if needed
            if (!user || user.accessToken !== token) {
              await updateProfile(authUser);
            }
          } else if (!isAuthPage) {
            console.log("isAuthPage", isAuthPage);
            // authUser is null, meaning the user is signed out or token is invalid
            console.log("Auth state changed to null, logging out");
            await logoutUser();
          }
        } catch (error) {
          console.error("Error handling token change:", error);
          await logoutUser();
        }
      }
    });

    return () => unsubscribe();
  }, []);
  // Refresh token from Firebase hook
  useEffect(() => {
    const FIFTEEN_MINUTES = 15 * 60 * 1000; // 15 minutes in milliseconds
    let refreshInterval = null;
    let isRefreshing = false;

    const refreshToken = async () => {
      if (isRefreshing || !auth.currentUser) return;

      try {
        isRefreshing = true;
        const token = await auth.currentUser.getIdToken(true); // Force refresh
        tokenId.current = token;
        console.log(
          "Token refreshed proactively at:",
          new Date().toISOString()
        );

        // Update the user context with the new token
        if (auth.currentUser) {
          await updateProfile({
            ...auth.currentUser,
            accessToken: token,
          });
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        // If token refresh fails, log the user out
        await logoutUser();
      } finally {
        isRefreshing = false;
      }
    };

    const setupRefreshInterval = (token) => {
      // Clear any existing interval
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }

      // Set up new interval
      tokenId.current = token;
      refreshInterval = setInterval(refreshToken, FIFTEEN_MINUTES);
      console.log(
        "Token refresh interval set up at:",
        new Date().toISOString()
      );
    };

    const unsubscribe = auth.onIdTokenChanged(async (authUser) => {
      if (authUser) {
        const token = await authUser.getIdToken();
        setupRefreshInterval(token);
      } else {
        if (refreshInterval) {
          clearInterval(refreshInterval);
          refreshInterval = null;
        }
      }
    });

    // Cleanup
    return () => {
      unsubscribe();
      if (refreshInterval) {
        clearInterval(refreshInterval);
        refreshInterval = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!isAuthPage && !logoutLoading) {
      const events = ["click", "scroll", "load", "keydown"];

      const eventHandler = () => {
        localStorage.setItem("lastInteractionTime", Date.now());
        if (timeoutId) {
          startTimer();
        }
      };

      const addEvents = () => {
        events.forEach((eventName) => {
          window.addEventListener(eventName, eventHandler);
        });

        startTimer();
      };

      const removeEvents = () => {
        events.forEach((eventName) => {
          window.removeEventListener(eventName, eventHandler);
        });
      };

      const startTimer = () => {
        if (!isAuthPage && !logoutLoading) {
          const sessionDuration = 1000 * 60 * 60 * 1; // 1h
          //const sessionDuration = 1000 * 30; // 2h
          timeoutId.current = setTimeout(() => {
            let lastInteractionTime = localStorage.getItem(
              "lastInteractionTime"
            );

            const millisecondsUntilExpiration =
              sessionDuration - (Date.now() - lastInteractionTime);
            console.log(
              "millisecondsUntilExpiration",
              millisecondsUntilExpiration,
              "for timeoutId ",
              timeoutId
            );
            console.log("logoutLoading", logoutLoading);
            if (millisecondsUntilExpiration >= 0) {
              startTimer();
            } else if (logoutLoading === false) {
              console.log("Session expired, log out");
              clearTimeout(timeoutId);
              logoutUser();
            }
          }, sessionDuration);
        }
      };

      if (user) {
        addEvents();

        return () => {
          removeEvents();
        };
      }
    }
  }, [timeoutId, push, user, logoutLoading, isAuthPage]);

  // Prevent pages from loading if not logged in (during redirect)
  if (user === null && isAuthPage === false) {
    return null;
  }

  // console.log("UserProvider state:", {
  //   user,
  //   tokenId: tokenId.current,
  //   loadingUser,
  // });

  return (
    <UserContext.Provider
      value={{
        ...user,
        loadingUser,
        updateProfile,
        accessToken: tokenId.current,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
